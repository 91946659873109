import React from "react";
// import "./privacy.scss"
import { useCompanyInfo } from "../../../hooks";
import { DEFAULT_BROKER_DETAILS } from "../../../commons/enum";

const PrivacyPage = () => {
  const { data: companyInfo = {} } = useCompanyInfo();
  const { adminDetails = {}, businessName = "", name = "" } = companyInfo;
  const { user = {} } = adminDetails;
  const { firstName = "", lastName = "" } = user;
  const fullName = `${firstName} ${lastName}`;
  const phoneNumber =
    companyInfo?.businessPhoneNumber ||
    user?.phoneNumber ||
    DEFAULT_BROKER_DETAILS.phoneNumber;
  const email =
    companyInfo?.customerSupportEmail ||
    user?.email ||
    DEFAULT_BROKER_DETAILS.supportEmail;
  return (
    <div className="privacy-container">
      <h1>How we keep data secure</h1>
      <p>
        {`${businessName || name}`} undertakes banking industry standards to
        ensure your data is safe and secure.
      </p>
      <p>
        Some of the ways we do this are: confidentiality requirements of our
        employees; document storage security policies; security measures for
        access to our systems; only giving access to personal information to a
        person who is verified to be able to receive that information; control
        of access to our buildings; and electronic security systems, such as
        firewalls and data encryption on our websites.
      </p>
      <p>
        We take protecting the security of your personal information seriously.
        We have a response plan that is designed to The data collected within
        the app is used by {`${name || businessName}`} and our white label
        partners for the purpose of advising on financial products and services.
        This is no different than your typical interaction with a finance
        broker, home loan lender or financial advisor and this is our only focus
        and we do not and never will sell or transfer your data to a third party
        without your permission.You have full control of your data and what
        advisors you share it with.
      </p>
      <h1>How we use your data</h1>
      <p>
        The data collected within the app is used by {`${name || businessName}`}{" "}
        and your financial advisor for the purpose of advising on financial
        products and services from your selected advisor. This is no different
        than your typical interaction with a finance broker, home loan lender or
        financial advisor. We do not and never will try to sell your data to any
        third party outside of your approved network, you will not receive any
        marketing calls or offers from engaging in our app that is our promise
        to you now and always.
        <br />
        <br />
        Respecting your privacy
      </p>
      <p>
        We respect your personal information, and this Privacy Policy explains
        how we handle it. The policy covers
        {`${businessName || name}`} and its white label partners.
      </p>
      <p>
        This Policy also includes our credit reporting policy, that is, it
        covers additional information on how we manage your personal information
        collected in connection with a credit application, or a credit facility.
        We refer to this credit-related information below as credit information.
      </p>
      <p>
        If you are in a country that is a member of the European Economic Area
        (EEA), the EU General Data Protection Regulation 2016/679
        (&apos;GDPR&apos;) governs the way we collect, use, hold, process and
        disclose your personal information. Under the GDPR, we are a data
        controller. We make decisions on how and why your personal information
        is processed.
      </p>
      <h1>What personal information do we collect and hold?</h1>
      <p>General information</p>
      <p>
        The types of information that we collect and hold about you could
        include:
      </p>
      <ul>
        <li>
          <p>
            ID information such as your name, postal or email address, telephone
            numbers, and date of birth;
          </p>
        </li>
        <li>
          <p>other contact details such as social media handles;</p>
        </li>
        <li>
          <p>financial details such as your tax file number; and</p>
        </li>
        <li>
          <p>other information we think is</p>
        </li>
      </ul>
      <h1>When the law authorises or requires us to collect information</h1>
      <p>
        We may collect information about you because we are required or
        authorised by law to collect it. There are laws which require us to
        collect personal information. For example, we require personal
        information to verify your identity under Australian Anti-Money
        Laundering law.
      </p>
      <h1>What do we collect via your website activity?</h1>
      <p>
        If you&apos;re an internet customer of ours, we monitor your use of
        internet services to ensure we can verify you and can receive
        information from us, and to identify ways we can improve our services
        for you.
      </p>
      <p>
        If you start but don&apos;t submit an on-line application, we can
        contact you using any of the contact details you&apos;ve supplied to
        offer help completing it. The information in applications will be kept
        temporarily then destroyed if the application is not completed.
      </p>
      <p>
        We also know that some customers like to engage with us through social
        media channels. We may collect information about you when you interact
        with us through
      </p>
      <p>
        these channels. However, for all confidential matters, we&apos;ll ensure
        we interact with you via a secure forum.
      </p>
      <p>
        To improve our services and products, we sometimes collect de-identified
        information from web users. That information could include IP addresses
        or geographical information to ensure your use of our web applications
        is secure.
      </p>
      <p>How do we collect your personal information?</p>
      <p>How we collect and hold your information</p>
      <p>
        Unless it&apos;s unreasonable or impracticable, we will try to collect
        personal information directly from you (referred to as &apos;solicited
        information&apos;). For this reason, it&apos;s important that you help
        us to do this and keep your contact details up-to-date.
      </p>
      <p>
        There are a number of ways in which we may seek information from you. We
        might collect your information when you fill out a form with us, when
        you&apos;ve given us a call or used our website. We also find using
        electronic means, such as email or SMS, a convenient way to communicate
        with you and to verify your details.
      </p>
      <p>How we collect your information from other sources</p>
      <p>
        Sometimes, we will collect information about you from other sources as
        the Privacy Act 1988 permits. We will do this only if it&apos;s
        reasonably necessary to do so, for example, where:
      </p>
      <ul>
        <li>
          <p>
            we collect information from third parties about the loan or lease
            made available to you arising out of the services we provide you;
          </p>
        </li>
        <li>
          <p>
            we can&apos;t get hold of you and we rely on public information (for
            example, from public registers or social media) or made available by
            third parties) to update your contact details; or
          </p>
        </li>
        <li>
          <p>
            we exchange information with your legal or financial advisers or
            other representatives.
          </p>
        </li>
      </ul>
      <p>
        What if you don&apos;t want to provide us with your personal
        information? If you don&apos;t provide your information to us, it may
        not be possible:
      </p>
      <ul>
        <li>
          <p>for us to give you the credit assistance you seek from us;</p>
        </li>
        <li>
          <p>
            to assist in finding a loan or lease relevant to your circumstances;
          </p>
        </li>
        <li>
          <p>verify your identity or protect against fraud; or</p>
        </li>
        <li>
          <p>
            to let you know about other products or services that might be
            suitable for your financial
          </p>
        </li>
      </ul>
      <p>How we collect and hold your credit information</p>
      <p>
        We will collect your credit information in the course of you answering
        the enquiries we make of you relating to the credit assistance you seek
        from us. In addition to what we
      </p>
      <p>
        1 However we&apos;ll never ask you for your security details in this way
        – if you are ever unsure, just contact us
      </p>
      <p>
        say above about collecting information from other sources, other main
        sources for collecting credit information are:
      </p>
      <ul>
        <li>
          <p>your co-loan applicants or co-borrowers;</p>
        </li>
        <li>
          <p>your guarantors/proposed guarantors;</p>
        </li>
        <li>
          <p>your employer, accountant, real estate agent or other referees;</p>
        </li>
        <li>
          <p>
            your agents and other representatives like the person who referred
            your business to us, your solicitors, conveyancers and settlement
            agents;
          </p>
        </li>
        <li>
          <p>organisations that help us to process credit applications;</p>
        </li>
        <li>
          <p>
            organisations that check the security you are offering such as
            valuers;
          </p>
        </li>
        <li>
          <p>
            bodies that issue identification documents to help us check your
            identity; and
          </p>
        </li>
        <li>
          <p>
            our service providers involved in helping us to process any
            application you make for credit through
          </p>
        </li>
      </ul>
      <p>What do we do when we get information we didn&apos;t ask for?</p>
      <p>
        Sometimes, people share information with us we haven&apos;t sought out
        (referred to as &apos;unsolicited information&apos;). Where we receive
        unsolicited personal information about you, we will check whether that
        information is reasonably necessary for our functions or activities. If
        it is, we&apos;ll handle this information the same way we do with other
        information we seek from you. If not, we&apos;ll ensure we do the right
        thing and destroy or de-identify it.
      </p>
      <p>When will we notify you that we have received your information?</p>
      <p>
        When we receive personal information from you directly, we&apos;ll take
        reasonable steps to notify you how and why we collected your
        information, who we may disclose it to and outline how you can access
        it, seek correction of it or make a complaint.
      </p>
      <p>
        Sometimes we collect your personal information from third parties. You
        may not be aware that we have done so. If we collect information that
        can be used to identify you, we will take reasonable steps to notify you
        of that collection.
      </p>
      <p>How do we take care of your personal information?</p>
      <p>
        We store information in different ways, including in paper and
        electronic form. The security of your personal information is important
        to us and we take reasonable steps to protect it from misuse,
        interference and loss, and from unauthorised access, modification or
        disclosure. Some of the ways we do this are:
      </p>
      <ul>
        <li>
          <p>document storage security policies;</p>
        </li>
        <li>
          <p>security measures for access to our systems; and</p>
        </li>
        <li>
          <p>
            only giving access to personal information to a person who is
            verified to be able to receive that information
          </p>
        </li>
      </ul>
      <p>
        We may store personal information physically or electronically with
        third party data storage providers. Where we do this, we use contractual
        arrangements to ensure those providers take appropriate measures to
        protect that information and restrict the uses to which they can put
        that information.
      </p>
      <p>What happens when we no longer need your information?</p>
      <p>
        We&apos;ll only keep your information for as long as we require it for
        our purposes. We may be required to keep some of your information for
        certain periods of time under law. When we no longer require your
        information, we&apos;ll ensure that your information is destroyed or
        de-identified.
      </p>
      <h1>How we use your personal information</h1>
      <p>
        What are the main reasons we collect, hold and use your information?
      </p>
      <p>
        Collecting your personal information allows us to provide you with the
        products and services you&apos;ve asked for. This means we can use your
        information to:
      </p>
      <ul>
        <li>
          <p>give you credit assistance;</p>
        </li>
        <li>
          <p>
            give you information about loan products or related services
            including help, guidance and advice;
          </p>
        </li>
        <li>
          <p>
            consider whether you are eligible for a loan or lease or any related
            service you requested including identifying or verifying you or your
            authority to act on behalf of a customer;
          </p>
        </li>
        <li>
          <p>assist you to prepare an application for a lease or a loan;</p>
        </li>
        <li>
          <p>
            administer services we provide, for example, to answer requests or
            deal with complaints; and
          </p>
        </li>
        <li>
          <p>
            administer payments we receive, or any payments we make, relating to
            your loan or
          </p>
        </li>
      </ul>
      <p>
        Can we use your information for marketing our products and services?
      </p>
      <p>
        We may use or disclose your personal information to let you know about
        other products or services we or a third party make available and that
        may be of interest to you.
      </p>
      <p>
        We will always let you know that you can opt out from receiving
        marketing offers.
      </p>
      <p>
        With your consent, we may disclose your personal information to third
        parties for the purpose of connecting you with other businesses or
        customers. You can ask us not to do this at any time. We won&apos;t sell
        your personal information to any organisation.
      </p>
      <h1>Yes, You Can Opt-Out</h1>
      <p>
        You can let us know at any time if you no longer wish to receive direct
        marketing offers from us. We will process your request as soon as
        practicable.
      </p>
      <h1>What are the other ways we use your information?</h1>
      <p>
        We&apos;ve just told you some of the main reasons why we collect your
        information, so here&apos;s some more insight into the ways we use your
        personal information including:
      </p>
      <ul>
        <li>
          <p>
            telling you about other products or services we make available and
            that may be of interest to you, unless you tell us not to;
          </p>
        </li>
        <li>
          <p>
            identifying opportunities to improve our service to you and
            improving our service to you;
          </p>
        </li>
        <li>
          <p>
            allowing us to run our business efficiently and perform general
            administrative tasks;
          </p>
        </li>
        <li>
          <p>preventing any fraud or crime or any suspected fraud or crime;</p>
        </li>
        <li>
          <p>as required by law, regulation or codes binding us; and</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>any purpose to which you have</p>
        </li>
      </ul>
      <h1>
        What are the grounds which we will deal with your personal information
        under the GDPR?
      </h1>
      <p>
        Under the GDPR, we must have a legal ground in order to process your
        personal information. The legal grounds that we may rely on are:
      </p>
      <ul>
        <li>
          <p>Performance of our contract with you;</p>
        </li>
        <li>
          <p>Compliance with a legal obligation;</p>
        </li>
        <li>
          <p>Where you have provided your consent; and</p>
        </li>
        <li>
          <p>
            For our legitimate interests: our main legitimate interests for
            processing your personal information are: fraud, security, due
            diligence, business operations and direct
          </p>
        </li>
      </ul>
      <h1>How long do you keep your information?</h1>
      <p>
        We required to keep some of your information for certain periods of time
        under law, such as the Corporations Act, the Anti-Money Laundering &amp;
        Counter-Terrorism Financing Act, and the Financial Transaction Reports
        Act for example.
      </p>
      <p>
        We are required to keep your information for 7 years from the closure of
        accounts, or otherwise as required for our business operations or by
        applicable laws.
      </p>
      <p>
        We may need to retain certain personal information after we cease
        providing you with products or services to enforce our terms, for fraud
        prevention, to identify, issue or resolve legal claims and/or for proper
        record keeping.
      </p>
      <h1>Who do we share your personal information with?</h1>
      <p>
        To make sure we can meet your specific needs and for the purposes
        described in &apos;How we use your personal information&apos;, we
        sometimes need to share your personal information with others. We may
        share your information with other organisations for any purposes for
        which we use your information.
      </p>
      <p>Sharing Your Information</p>
      <p>
        We may use and share your information with other organisations for any
        purpose described above.
      </p>
      <p>
        Sharing with your representatives and referees We may share your
        information with:
      </p>
      <ul>
        <li>
          <p>
            your representative or any person acting on your behalf (for
            example, lawyers, settlement agents, accountants or real estate
            agents); and
          </p>
        </li>
        <li>
          <p>your referees, like your employer, to confirm details about</p>
        </li>
      </ul>
      <p>Sharing with third parties</p>
      <p>
        We may share your information with third parties in relation to services
        we provide to you. Those third parties may include:
      </p>
      <ul>
        <li>
          <p>
            the mortgage aggregator through whom we may submit loan or lease
            applications to lenders or lessors on the mortgage aggregator&apos;s
            panel;
          </p>
        </li>
        <li>
          <p>
            the Australian Credit Licence holder that authorises us to engage in
            credit activities;
          </p>
        </li>
        <li>
          <p>referrers that referred your business to us;</p>
        </li>
        <li>
          <p>valuers;</p>
        </li>
        <li>
          <p>
            lenders, lessors, lender&apos;s mortgage insurers and other loan or
            lease intermediaries;
          </p>
        </li>
        <li>
          <p>
            organisations, like fraud reporting agencies, that may identify,
            investigate and/or prevent fraud, suspected fraud, crimes, suspected
            crimes, or other misconduct;
          </p>
        </li>
        <li>
          <p>
            government or regulatory bodies (including ASIC and the Australian
            Taxation Office) as required or authorised by law. In some
            instances, these bodies may share the information with relevant
            foreign authorities;
          </p>
        </li>
        <li>
          <p>guarantors and prospective guarantors of your loan or lease;</p>
        </li>
        <li>
          <p>
            service providers, agents, contractors and advisers that assist us
            to conduct our business for purposes including, without limitation,
            storing or analysing information;
          </p>
        </li>
        <li>
          <p>
            any organisation that wishes to take an interest in our business or
            assets; and
          </p>
        </li>
        <li>
          <p>any third party to which you consent to us sharing your</p>
        </li>
      </ul>
      <p>Sharing outside of Australia</p>
      <p>
        We may store your information in cloud or other types of networked or
        electronic storage. As electronic or networked storage can be accessed
        from various countries via an internet connection, it&apos;s not always
        practicable to know in which country your information may be held. If
        your information is stored in this way, disclosures may occur in
        countries other than those listed.
      </p>
      <p>
        Overseas organisations may be required to disclose information we share
        with them under a foreign law. In those instances, we will not be
        responsible for that disclosure.
      </p>
      <p>
        Where we transfer your information from the EEA&apos; to a recipient
        outside the EEA we will ensure that an adequate level of protection is
        in place to protect your personal information such as putting in place
        contractual protections to ensure the security of your information.
      </p>
      <h1>How do you access your personal information?</h1>
      <p>How you can generally access your information</p>
      <p>
        We&apos;ll always give you access to your personal information unless
        there are certain legal reasons why we can&apos;t. You can ask us in
        writing to access your personal information that we hold. In some cases
        we may be able to deal with your request over the phone.
      </p>
      <p>
        We will give you access to your information in the form you want it
        where it&apos;s reasonable and practical. We may charge you a small fee
        to cover our costs when giving you access, but we&apos;ll always check
        with you first.
      </p>
      <p>
        We&apos;re not always required to give you access to your personal
        information. Some of the situations where we don&apos;t have to give you
        access include when:
      </p>
      <ul>
        <li>
          <p>we believe there is a threat to life or public safety;</p>
        </li>
        <li>
          <p>there is an unreasonable impact on other individuals;</p>
        </li>
        <li>
          <p>the request is frivolous;</p>
        </li>
        <li>
          <p>
            the information wouldn&apos;t be ordinarily accessible because of
            legal proceedings;
          </p>
        </li>
        <li>
          <p>it would prejudice negotiations with you;</p>
        </li>
        <li>
          <p>it would be unlawful;</p>
        </li>
        <li>
          <p>
            it would jeopardise taking action against serious misconduct by you;
          </p>
        </li>
        <li>
          <p>
            it would be likely to harm the activities of an enforcement body
            (e.g. the police); or
          </p>
        </li>
        <li>
          <p>it would harm the confidentiality of our commercial</p>
        </li>
      </ul>
      <p>
        If we can&apos;t provide your information in the way you&apos;ve
        requested, we will tell you why in writing. If you have concerns, you
        can complain. See &apos;Contact Us&apos;.
      </p>
      <h1>How do you correct your personal information?</h1>
      <p>How we correct your information</p>
      <p>
        Contact us if you think there is something wrong with the information we
        hold about you and we&apos;ll try to correct it if it&apos;s:
      </p>
      <ul>
        <li>
          <p>inaccurate;</p>
        </li>
        <li>
          <p>out-of-date;</p>
        </li>
        <li>
          <p>incomplete;</p>
        </li>
        <li>
          <p>irrelevant; or</p>
        </li>
      </ul>
      <p>
        If you are worried that we have given incorrect information to others,
        you can ask us to tell them about the correction. We&apos;ll try and
        help where we can - if we can&apos;t, then we&apos;ll let you know in
        writing.
      </p>
      <h1>
        What additional things do we have to do to correct your credit
        information?
      </h1>
      <p>
        If you ask us to correct credit information, we will help you with this
        in the following way.
      </p>
      <p>Helping you manage corrections</p>
      <p>
        Whether we made the mistake or someone else made it, we are required to
        help you ask for the information to be corrected. So we can do this, we
        might need to talk to others. However, the most efficient way for you to
        make a correction request is to send it to the organisation which made
        the mistake.
      </p>
      <p>Where we correct information</p>
      <p>
        If we&apos;re able to correct the information, we&apos;ll let you know
        within five business days of deciding to do this. We&apos;ll also let
        the relevant third parties know as well as any others you tell us about.
        If there are any instances where we can&apos;t do this, then we&apos;ll
        let you know in writing.
      </p>
      <p>Where we can&apos;t correct information</p>
      <p>
        If we&apos;re unable to correct your information, we&apos;ll explain why
        in writing within five business days of making this decision. If you
        have any concerns, you can access our external dispute resolution scheme
        or make a complaint to the Office of the Australian Information
        Commissioner.
      </p>
      <p>Time frame for correcting information</p>
      <p>
        If we agree to correct your information, we&apos;ll do so within 30 days
        from when you asked us, or a longer period that&apos;s been agreed by
        you.
      </p>
      <p>
        If we can&apos;t make corrections within a 30 day time frame or the
        agreed time frame, we must:
      </p>
      <ul>
        <li>
          <p>
            let you know about the delay, the reasons for it and when we expect
            to resolve the matter;
          </p>
        </li>
        <li>
          <p>ask you to agree in writing to give us more time; and</p>
        </li>
        <li>
          <p>
            let you know you can complain to our external dispute resolution
            scheme or the Office of the Australian Information
          </p>
        </li>
      </ul>
      <h1>How do you make a complaint?</h1>
      <p>How do you generally make a complaint?</p>
      <p>
        If you have a complaint about how we handle your personal information,
        we want to hear from you. You are always welcome to contact us .
      </p>
      <p>You can contact us by using the details below</p>
      <p>{`${fullName}`}</p>
      <p>
        Contact Number: {`${phoneNumber}`} Email: {`${email}`}
      </p>
      <p>
        We are committed to resolving your complaint and doing the right thing
        by our customers. Most complaints are resolved quickly, and you should
        hear from us within five business days.
      </p>
      <p>Need more help?</p>
      <p>
        If you still feel your issue hasn&apos;t been resolved to your
        satisfaction, then you can raise your concern with the Office of the
        Australian Information Commissioner:
      </p>
      <ul>
        <li>
          <p>
            Online:{" "}
            <a href="http://www.oaic.gov.au/privacy"> oaic.gov.au/privacy</a>
          </p>
        </li>
        <li>
          <p>Phone: 1300 363 992</p>
        </li>
        <li>
          <p>Email: enquiries@oaic.gov.au</p>
        </li>
        <li>
          <p>Fax: +61 2 9284 9666</p>
        </li>
        <li>
          <p>
            Mail: GPO Box 5218 Sydney NSW 2001 or GPO Box 2999 Canberra ACT 2601
          </p>
        </li>
      </ul>
      <p>
        If you are located in the EEA, you can contact the relevant data
        protection authority (for example in the place you reside or where you
        believe we breached your rights). For example, the Office of the UK
        Information Commissioner:
      </p>
      <p>Office of the UK Information Commissioner</p>
      <ul>
        <li>
          <p>
            Online: <a href="http://www.ico.gov.uk/">www.ico.gov.uk</a>
          </p>
        </li>
        <li>
          <p>Phone: 0303 123 1113</p>
        </li>
        <li>
          <p>
            Live chat:{" "}
            <a href="https://ico.org.uk/global/contact-us/live-chat">
              https://ico.org.uk/global/contact-us/live-chat
            </a>
          </p>
        </li>
      </ul>
      <h1>
        What additional things do we have to do to manage your complaints about
        credit information?
      </h1>
      <p>
        If your complaint relates to how we handled your access and correction
        requests
      </p>
      <p>
        You may take your complaint directly to our external dispute resolution
        scheme or the Office of the Australian Information Commissioner. You are
        not required to let us try to fix it first.
      </p>
      <p>For all other complaints relating to credit information</p>
      <p>
        If you make a complaint about things (other than an access request or
        correction request) in relation to your credit information, we will let
        you know how we will deal with it within seven days.
      </p>
      <p>Ask for more time if we can&apos;t fix things in 30 days</p>
      <p>
        If we can&apos;t fix things within 30 days, we&apos;ll let you know why
        and how long we think it will take. We will also ask you for an
        extension of time to fix the matter. If you have any concerns, you may
        complain to our external dispute resolution scheme or the Office of the
        Australian Information Commissioner.
      </p>
      <p>Letting you know about our decision</p>
      <p>
        We&apos;ll let you know about our decision within 30 days or any longer
        agreed time frame. If you have any concerns, you may complain to our
        external dispute resolution scheme or the Office of the Australian
        Information Commissioner.
      </p>
      <h1>Your Rights under GDPR</h1>
      <p>If you reside in the EEA, you can also:</p>
      <ul>
        <li>
          <p>
            object to the processing or your personal information or ask us to
            delete, or restrict or stop using your personal information. There
            may be circumstances where we are required to, or entitled to retain
            or continue using your information.
          </p>
        </li>
        <li>
          <p>
            withdraw your consent to our processing of your information. We may
            continue to process our information if we have another legitimate
            ground to do
          </p>
        </li>
        <li>
          <p>
            ask us to send an electronic copy of your personal information,
            including to another
          </p>
        </li>
      </ul>
      <p>
        You can contact us if you wish to exercise these rights. See
        &apos;Contact Us&apos; for more information If we refuse any request you
        make in relation to these right, we will write to you to explain why and
        how you can make a complaint about our decision.
      </p>
      <h1>Contact Us</h1>
      <p>
        We care about your privacy. Please contact us if you have any questions
        or comments about our privacy policies and procedures. We welcome your
        feedback.
      </p>
      <p>You can contact us by using the details below:</p>
      <p>{`${fullName}`}</p>
      <p>
        Contact number: {`${phoneNumber}`} Email: {`${email}`}
      </p>
      <p>
        What if you want to interact with us anonymously or use a pseudonym?
      </p>
      <p>
        If you have general enquiry type questions, you can choose to do this
        anonymously or use a pseudonym. We might not always be able to interact
        with you this way, however, as we are often governed by regulations that
        require us to know who we&apos;re dealing with. In general, we
        won&apos;t be able to deal with you anonymously or where you are using a
        pseudonym when:
      </p>
      <ul>
        <li>
          <p>it is impracticable; or</p>
        </li>
        <li>
          <p>
            we are required or authorised by law or a court/tribunal order to
            deal with you personally.
          </p>
        </li>
      </ul>
      <p>What do we do with government-related identifiers?</p>
      <p>
        In certain circumstances we may be required to collect
        government-related identifiers such as your tax file number. We will not
        use or disclose this information unless we are authorised by law.
      </p>
      <h1>Changes to this Privacy Policy</h1>
      <p>
        This Policy may change. We will let you know of any changes to this
        Policy by correspondence via e-mail or you may contact us for a copy of
        the most up to date policy at any time.
      </p>
      <div>
        <br />
      </div>
    </div>
  );
};

export default PrivacyPage;
